@import '../../../styles/variables';
.root {
  display: flex;
  flex-direction: column;
  @include marginBottom;

  width: $w_12_ColumnWidth;

  .text {
    margin-left: $contentColumnWidthPlusSpace;
    padding-bottom: 2.4rem;
    font-weight: 300;
    line-height: 140%;

    p {
      font-size: 2.2rem;
    }
  }
  @media screen and (max-width: $desktopBreakpoint) {
    max-width: 100%;
  }
}

.center {
  max-width: unset;
  align-items: center;
  h4,
  p {
    text-align: left;
  }
}

.right {
  padding-left: $w_9_ColumnWidth;
  @media screen and (max-width: $desktopBreakpoint) {
    padding-left: unset;
  }
}
