@import '../../../styles/variables';
.button {
  display: block;
  width: fit-content;
  cursor: pointer;
  margin-top: $padding * 2;
  padding: 1.28rem 2.56rem;
  transition: all 250ms ease-out;
  font-size: 1.5rem;
  height: 4rem;
  box-sizing: border-box;
  line-height: 1rem;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: transparent;

  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 0, 0);

  &:hover {
    background-color: white;
    border: '1px solid white';
    color: black;
  }
}

.root {
  display: flex;
  flex-direction: column;
  @include paddingBottom;
  width: $w_6_ColumnWidth;
  // height: 2842px;
  height: auto;
  padding-left: $contentColumnWidthPlusSpace;
  > span,
  h4 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding-bottom: 2.6rem;
    text-transform: uppercase;
  }
  > span {
    font-family: 'Roboto Mono';
    font-weight: 400;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
  }
  > span,
  > h4,
  > div {
    @include fade-start;
  }
  @media screen and (max-width: $desktopBreakpoint) {
    max-width: 95%;
  }
  &.in-view {
    > span,
    > h4,
    > div {
      @include fade-end;
    }
  }

  > div {
    > div {
      > div {
        > p {
          // margin-left: 18.4%;
        }
      }
    }
  }
}

.container {
  &.closed {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}

.left {
  @media screen and (max-width: $desktopBreakpoint) {
    display: flex;
    width: $w_14_ColumnWidth;
    padding-left: $contentColumnWidthPlusSpace;
    box-sizing: border-box;
    width: 90%;
    > span,
    > h4 {
      line-height: 2.6rem;
    }
  }
}

.center {
  display: flex;
  width: $w_14_ColumnWidth;
  padding-left: $contentColumnWidthPlusSpace;
  box-sizing: border-box;
  @media screen and (max-width: $desktopBreakpoint) {
    width: 90%;
    > span,
    > h4 {
      line-height: 2.6rem;
    }
  }
}

.right {
  padding-left: $w_6_ColumnWidth + $contentColumnSpace;
  @media screen and (max-width: $desktopBreakpoint) {
    padding-left: unset;
  }
}

.left,
.center,
.right {
  p,
  h4 {
    text-align: left;
    font-size: 2.2rem;
    line-height: 3.1rem;
    font-weight: 300;
  }
  h4 {
    font-size: 1.5rem;
  }
}
