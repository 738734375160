@import '../../../styles/variables';
.root {
  display: flex;
  flex-direction: column;
  @include paddingY;
  color: black;
  margin-left: $contentColumnWidthPlusSpace;

  h4 {
    font-weight: 500;
    font-size: 4.8rem;
    line-height: 140%;
    margin-bottom: 3vh;
    font-style: normal;
    text-align: left;
  }
  p {
    font-weight: 300;
    font-size: 3rem;
    line-height: 140%;
    text-align: left;
  }

  ul {
    li {
      font-size: 1.9rem;
      line-height: 3.1rem;
    }
  }

  @media screen and (max-width: $desktopBreakpoint) {
    max-width: 95%;
  }

  @media screen and (max-width: $largePhoneBreakpoint) {
    h4 {
      font-size: 2.8rem;
      line-height: 140%;
      margin-bottom: 2.4vh;
    }
    p {
      font-size: 2.2rem;
      line-height: 140%;
    }

    ul {
      li {
        font-size: 1.6rem;
        line-height: 2.6rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.center {
  max-width: unset;
  align-items: center;
  text-align: center;
  h4 {
    width: 100%;
    text-align: left;
  }
}

.centerText {
  h4 {
    text-align: center;
  }
  p {
    text-align: center;
  }
}
