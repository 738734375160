@import '../../../styles/variables';
.root {
  display: flex;
  flex-direction: row;
  font-size: '3.2rem';
  font-weight: 600;
  position: relative;
  height: 50vh;
  min-height: 300px;
  width: $w_12_ColumnWidth;

  @media screen and (max-width: $desktopBreakpoint) {
    // width: 100vw !important;
  }

  > div {
    position: relative;
    flex: 1;
    display: flex;
    width: 100%;

    @media screen and (max-width: $desktopBreakpoint) {
      display: block;
    }

    > div {
      position: absolute;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;

      @media screen and (max-width: $desktopBreakpoint) {
        position: relative;
      }
    }
  }
  &.one-image {
  }
  &.two-image {
  }
  &.three-images {
    width: 100%;
    > div {
      flex: 1;
      width: 100vw;
    }
    > div:first-of-type {
      z-index: 3;

      > div {
        height: 50%;
        width: 110%;
        bottom: 10%;
        left: -46px;
        @media screen and (max-width: $desktopBreakpoint) {
          height: 50vh;
          width: 91.666vw;
          top: 0;
          bottom: auto;
          left: 0;
        }
      }
    }
    > div:nth-of-type(2) {
      z-index: 5;
      > div {
        height: 70%;
        left: -5rem;
        @media screen and (max-width: $desktopBreakpoint) {
          height: 50vh;
          width: 100vw;
          top: 0;
          bottom: auto;
          left: 100vh;
        }
      }
    }
    > div:nth-of-type(3) {
      z-index: 1;
      > div {
        width: calc(120% + 56px);
        left: -20%;
        @media screen and (max-width: $desktopBreakpoint) {
          height: 50vh;
          width: 100vw;
          top: 0;
          bottom: auto;
          left: 200vw;
        }
      }
    }
  }
}
