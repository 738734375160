@import '../../../styles/variables';
.center {
  .wrapper-outer {
    .wrapper-inner {
      // padding: $w_10_ColumnWidth;
      // padding-left: $contentColumnWidthPlusSpace;
      // box-sizing: border-box;

      > div,
      > h4 {
        // margin-left: $contentColumnWidthPlusSpace;
      }
    }
  }
}

.root {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: $maxWidth;
  @include full-width;
  @include paddingY;
  color: white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &.in-view {
    .wrapper-outer {
      .wrapper-inner {
        > div,
        h4 {
          @include fade-end;
        }

        h4 {
          margin-bottom: 2rem;
        }
      }
    }
  }

  h4 {
    font-family: 'Roboto Mono';
    font-weight: 400;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
  }
  .wrapper-outer {
    width: $pageMaxWidth;
    display: flex;
    align-items: center;
    justify-content: center;
    .wrapper-inner {
      display: flex;
      flex-direction: column;
      @include max-plug-width;
      // width: $w_10_ColumnWidth;
      a {
        color: white;

        &:hover {
          color: $green;
        }
      }

      /* pre in-view settings */
      > div,
      h4 {
        @include fade-start;
        margin-left: $contentColumnWidthPlusSpace;
      }

      .link {
        position: relative;
        width: fit-content;
        display: flex;
        padding: 1rem 0;

        .in-view & {
          background-color: blueviolet;
        }

        a {
          cursor: pointer;
          font-size: 4.8rem;
        }
        span {
          position: absolute;
          right: -50px;
          top: 0;
          font-family: 'Roboto Mono', monospace;
          font-weight: normal;
          font-size: 1.2rem;
          line-height: 1.583rem;
          right: -1.9rem;
          top: 3.9rem;
        }
      }
      .link:hover {
        color: $blue;
      }
      .ctaWrapper {
        padding-top: min(15vh, 11.6rem);
        padding-bottom: 3rem;
        font-size: 2.2rem;
        line-height: 2.2rem;
        font-weight: 400;
        p > span {
          font-size: 3.2rem;
        }

        > span {
          margin-top: $padding;
        }

        a {
          z-index: 1;
          color: inherit;
        }
      }
    }
  }
}
