@import '../../../styles/variables';
.root {
    display: grid;
    position: relative;
    color: 'red';
    font-size: '3.2rem';
    background-color: lightsalmon;
    font-weight: 600;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid lightcoral;
    @include oneColumnLayout;
    &:after {
        content: 'not found';
        position: absolute;
        top: 10px;
        left: 10px;
        font-family: Ariel, 'Roboto Mono', verdana;
        font-size: 1.4rem;
    }
}