@import '../../../styles/variables';
.root {
  display: block;
  width: fit-content;
  cursor: pointer;
  margin-top: $padding * 2;
  padding: 1.28rem 2.56rem;
  transition: all 250ms ease-out;
  font-size: 1.5rem;
  height: 4rem;
  box-sizing: border-box;
  line-height: 1rem;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: transparent;

  a {
    color: white;
    text-decoration: none;
    font-family: 'Roboto Mono';
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    background-color: transparent;
    line-height: 100%;
  }

  &:hover {
    a {
      color: black;
    }
  }
}
