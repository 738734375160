@import '../../../styles/variables';
.root {
    display: flex;
    flex-direction: column;
    width: calc(100% + 8.334%);
    margin-left: -4.55%;
    align-self: center;
    @media screen and (max-width: $desktopBreakpoint) {
        margin-left: -18px;
    }
    &.center {
        align-items: center;
    }
    &.right {
        align-items: flex-end;
    }
}

.singleImg {
    width: 65%;
}

.singleDivImg {
    max-width: 760px;
    display: flex;
    width: 33%;
    height: 20vh;
    min-height: 300px;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}