@import '../../../styles/variables';
.root {
  display: flex;
  position: relative;
  font-size: '3.2rem';
  background-color: $lightGray;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  box-sizing: border-box;
  cursor: pointer;
  height: 115px;
  width: $maxWidth;
  @include full-width;
  text-decoration: none;

  .wrapper-outer {
    width: $pageMaxWidth;
    display: flex;
    align-items: center;
    justify-content: center;
    .wrapper-inner {
      display: flex;
      align-items: center;
      @include max-plug-width;
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 140%;
        margin-left: $contentColumnWidthPlusSpace;

        transition: color 150ms ease-out;
        color: $gray;
      }
      svg {
        margin-left: 2rem;
        transition: filter 150ms ease-out;
        transform: translate3d(0, 0.2rem, 0);
        filter: invert(32%) sepia(2%) saturate(2658%) hue-rotate(144deg) brightness(85%)
          contrast(85%);
      }
      &:hover {
        span {
          color: $green;
        }
        svg {
          filter: invert(76%) sepia(72%) saturate(269%) hue-rotate(119deg) brightness(91%)
            contrast(90%);
        }
      }
    }
  }
}
