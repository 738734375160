@import '../../../../styles/variables';
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: $w_8_ColumnWidth;
  @media screen and (max-width: $desktopBreakpoint) {
    width: 100%;
  }
  > div {
    padding: 3rem;
    p {
      font-size: 1.6rem;
    }
  }
  button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 11.2rem;
    width: 100%;
    padding: 0 3rem 0 1.5rem;
    > span {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      h4 {
        width: 45%;
        text-align: left;
        font-weight: 500;
        font-size: 2rem;
        line-height: 3rem;
      }
      p {
        width: 45%;
        font-family: 'Roboto Mono';
        font-weight: 300;
        font-size: 1.8rem;
        line-height: 3rem;
        padding-left: 4rem;
        text-align: left;
      }
      @media screen and (max-width: $desktopBreakpoint) {
        h4 {
          font-size: 1.4rem;
          line-height: 1.96rem;
        }
        p {
          font-size: 1.2rem;
          line-height: 1.68rem;
        }
      }
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #435053;
      bottom: 100%;
      left: 0;
    }
    // &:first-of-type {
    //     &:before {
    //         content: '';
    //         position: absolute;
    //         width: 100%;
    //         height: 1px;
    //         background-color: #435053;
    //         bottom: 0;
    //         left: 0;
    //     }
    // }
  }
}
