@import '../../../styles/variables';
.root {
  display: flex;
  position: relative;
  background-color: $lightGray;
  @include marginBottom;
  align-items: center;
  justify-content: center;
  width: $maxWidth;
  @include full-width;
  @media screen and (max-width: $desktopBreakpoint) {
    // padding-left: $contentColumnWidthPlusSpace;
    // padding-right: $contentColumnWidthPlusSpace;
  }
  .wrapper-outer {
    width: $pageMaxWidth;
    display: flex;
    position: relative;
    background-color: $lightGray;
    @include paddingY;
    align-items: center;
    justify-content: center;
    .wrapper-inner {
      display: flex;
      @include max-plug-width;
      .title {
        font-weight: 500;
        text-transform: uppercase;
        // padding-right: $padding;
        font-size: 1.6rem;
        line-height: 3.4rem;
        width: $contentColumnWidthPlusSpace;
      }
      @media screen and (max-width: $desktopBreakpoint) {
        flex-direction: column;
        width: $w_9_ColumnWidth;
      }
      p {
        font-size: 2.2rem;
        line-height: 140%;
      }
    }
  }
  .center {
    .wrapper-outer {
      .wrapper-inner {
        > div {
          margin: auto;
        }
      }
    }
  }
  .instaWrapper {
    display: flex;
    padding-bottom: $padding;
    text-decoration: none;
    font-weight: 500;
    .img {
      margin-right: $padding;
      height: 22px;
      width: 22px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
}
