@import '../../../styles/variables';
.root {
  position: relative;
  height: 100%;
  height: calc(100vh - 70px);
  @include full-width;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include marginBottom;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 50 50'%3E%3Cpath d='M 25 2 C 12.316406 2 2 12.316406 2 25 C 2 37.683594 12.316406 48 25 48 C 37.683594 48 48 37.683594 48 25 C 48 12.316406 37.683594 2 25 2 Z M 19 35 L 19 15 L 36 25 Z'%3E%3C/path%3E%3C/svg%3E")
      16 16,
    pointer;
  &.playing {
    cursor: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 565.648 565.648'  fill='white'  height='50' viewBox='0 0 565.648 565.648' width='50' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m282.824 0c-155.947 0-282.824 126.877-282.824 282.824s126.877 282.824 282.824 282.824 282.824-126.877 282.824-282.824-126.877-282.824-282.824-282.824zm-35.353 388.883h-70.706v-212.118h70.706zm141.412 0h-70.706v-212.118h70.706z'/%3E%3C/svg%3E%0A")
        16 16,
      pointer;
  }
  @media screen and (max-width: $desktopBreakpoint) {
    height: calc(100vh - 100px);
    justify-content: space-between;
  }
  .center-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      video {
        object-fit: cover;
        object-position: center center;
        // min-height: 500px;
        height: 100%;
        width: 1500px;
        object-fit: cover;
      }
    }
  }
}

.wrapper-outer {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @include max-plug-width;
  width: 100%;
  height: 100%;
}

.wrapper-inner {
  display: flex;
  flex-direction: column;
  @include max-plug-width;
  color: white;
  // z-index: 99;
  bottom: 20vh;
  font-weight: normal;
  font-size: 3rem;
  line-height: 140%;
  // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cpath d='M 25 2 C 12.316406 2 2 12.316406 2 25 C 2 37.683594 12.316406 48 25 48 C 37.683594 48 48 37.683594 48 25 C 48 12.316406 37.683594 2 25 2 Z M 19 35 L 19 15 L 36 25 Z'%3E%3C/path%3E%3C/svg%3E");
  @media screen and (max-width: $desktopBreakpoint) {
    position: absolute;
    bottom: 50px;
  }
  .play {
    padding: 1rem;
    border: 2px solid #ffffff;
    background-color: transparent;
    width: fit-content;
    min-width: 100px;
    transition: all 250ms ease-in-out;
    font-size: 1.1rem;
    line-height: 1.1rem;
    svg {
      padding-right: 1rem;
    }
  }
  .play:hover {
    background-color: white;
    color: black;
    path {
      fill: black;
    }
  }
  @media screen and (max-width: $desktopBreakpoint) {
    .play {
      text-transform: uppercase;
      padding: 1.12rem;
    }
  }
}

.desktopContent {
  @media screen and (max-width: $desktopBreakpoint) {
    display: none;
  }
}

.scroll {
  display: flex;
  align-items: center;
  width: 392px;
  background-color: $blue;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 99;
  padding: $padding * 1.4;
  cursor: pointer;
  z-index: 1;
  @media screen and (max-width: $desktopBreakpoint) {
    position: relative;
    width: 100%;
    height: auto;
  }
  > svg {
    transform: translate3d(0, 0, 0);
    height: 20px;
    transition: transform 100ms ease-out;
    @media screen and (max-width: $desktopBreakpoint) {
      display: none;
    }
  }
  @media screen and (max-width: $desktopBreakpoint) {
    padding: 2.8rem 0;
  }
}

.scroll:hover svg {
  transition: transform 150ms ease-out;
  transform: translate3d(0, 4px, 0);
}

.mobileContent {
  display: none;
  @media screen and (max-width: $desktopBreakpoint) {
    display: flex;
    flex-direction: column;
    padding-left: $contentColumnWidthPlusSpace;
    margin-left: $pageGutter;
    width: $w_9_ColumnWidth;
    font-weight: normal;
    font-size: 5vw;
    line-height: 5vw;
    text-align: left;

    justify-content: center;

    svg {
      position: absolute;
      // margin-top: 2.2rem;
      align-self: flex-end;
      width: 5vw;
      transform: translate3d(0, 0, 0);
    }
  }
}
