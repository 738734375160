@import '../../../styles/variables';

.root {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  @include paddingY;

  p,
  a {
    font-weight: 300;
    font-size: 5rem;
    line-height: 1.16;
    color: black;
    text-align: center;

    @media(max-width: 600px) {
      font-size: 3rem;
    }
  }

  a {
    font-style: italic;
    position: relative;
    font-family: 'Roboto Mono';
    font-weight: 100;


  }

  code {
    font-weight: inherit;
    display: inline-block;
  }

  a code {
    text-decoration: underline;
  }
}