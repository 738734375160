@import '../../../../../styles/variables';
.root {
    display: flex;
    position: relative;
    flex-direction: column;
    >span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 300;
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
    .input-container {
        position: relative;
        display: flex;
        align-items: flex-end;
        margin-bottom: 1rem;
        height: 60px;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: black;
        }
    }
    input {
        width: auto;
        background-color: transparent;
        vertical-align: bottom;
        line-height: 2rem;
        width: 100%;
        height: 40px;
        font-size: 30px;
    }
}