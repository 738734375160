@import './colors';
// PRINT
$printWidth: 100%;
$maxPrintWidth: 775px;
// GLOBALS
$largePhoneBreakpoint: 414px;
$desktopBreakpoint: 768px;
$padding: 20px;
// FONTS

/* 62.5% of 16px = 10px */

$font-size: 62.5%;
// LAYOUT
$pageWidth: 100vw;
$maxWidth: 2200px;
$maxWidth: 1440px;
$gutter: 60px;
// @media screen and (max-width: $desktopBreakpoint) {
//     $gutter: 20px
// }

/* 62.5% of 16px = 10px */

$pageGutter: 4.167%;
$gutterMobile: 20px;
$pageMaxWidth: calc(#{$maxWidth} - #{$gutter * 2});
$pageMaxInnerWidth: calc(100% - #{$pageGutter * 2});
// SPACING
$componentYPadding: $pageGutter * 2;
$componentXPadding: $pageGutter * 0.5;
$contentColumnCount: 4;
$contentColumnSpace: 1.389%;
$contentColumnSpaceMobile: 2.9%;
// TODO: create a calc for calkcint the
$contentColumnSpaceInPx: 18px; // This is based on width - gutter = 1380px
// $fsContentColumnSpace: calc();
$contentColumnWidth: 7.057%;
$contentColumnWidthMobile: 22.8%;
$contentColumnWidthPlusSpace: $contentColumnWidth + $contentColumnSpace;
$contentColumnWidthMobilePlusSpace: $contentColumnWidthMobile + $contentColumnSpaceMobile;
// size formulas
$content_67_33: 67% 33%;
$content_75_25: 75% 25%;
$content_auto_auto: auto auto;
$content_100: 100%;
$w_2_ColumnWidth: ($contentColumnWidthPlusSpace * 1 + $contentColumnWidth);
$w_3_ColumnWidth: ($contentColumnWidthPlusSpace * 2 + $contentColumnWidth);
$w_4_ColumnWidth: ($contentColumnWidthPlusSpace * 4 + $contentColumnWidth);
$w_6_ColumnWidth: ($contentColumnWidthPlusSpace * 5 + $contentColumnWidth);
$w_8_ColumnWidth: ($contentColumnWidthPlusSpace * 7 + $contentColumnWidth);
$w_9_ColumnWidth: ($contentColumnWidthPlusSpace * 8 + $contentColumnWidth);
$w_10_ColumnWidth: ($contentColumnWidthPlusSpace * 9 + $contentColumnWidth);
$w_11_ColumnWidth: ($contentColumnWidthPlusSpace * 10 + $contentColumnWidth);
$w_12_ColumnWidth: ($contentColumnWidthPlusSpace * 11 + $contentColumnWidth);
$w_13_ColumnWidth: ($contentColumnWidthPlusSpace * 12 + $contentColumnWidth);
$w_14_ColumnWidth: ($contentColumnWidthPlusSpace * 13 + $contentColumnWidth);
$w_16_ColumnWidth: ($contentColumnWidthPlusSpace * 15 + $contentColumnWidth);
@mixin max-plug-width {
  width: $pageMaxInnerWidth;
  max-width: $pageMaxWidth;
}

// Tablet -
@mixin full-width {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
}

@function calculateGridWidth($columns, $space) {
  $sum: ($columns - 1) * $contentColumnWidthPlusSpace + $contentColumnWidth + $space;
  @return $sum;
}

@mixin oneColumnLayout {
  // @include max-plug-width;
  grid-template-columns: auto;
  grid-template-rows: 100%;
}

@mixin twoColumnLayout {
  grid-template-columns: $w_16_ColumnWidth;
  grid-template-rows: auto;
}

@mixin threeRowAutoLayout {
  grid-template-columns: 100%;
  row-gap: 5%;
  grid-template-rows: auto auto auto;
}

@mixin twoColumnLayout_100_and_10bar_width {
  grid-template-columns: $w_10_ColumnWidth;
  grid-template-rows: $content_100;
  justify-content: center;
}

@mixin threeColumnLayout_8bar_auto_auto {
  grid-template-columns: 60% auto auto;
  column-gap: $contentColumnSpace;
  justify-content: space-between;

  @media screen and (max-width: $desktopBreakpoint) {
    grid-template-columns: auto 120px 55px;
    column-gap: 0;
    justify-content: space-between;
  }
}

@mixin twoRowLayout_100_and_16bar_width {
  grid-template-columns:
    minmax(70px, 7vw) minmax(110px, $w_3_ColumnWidth) minmax(auto, $w_9_ColumnWidth)
    minmax(110px, $w_3_ColumnWidth);
  grid-template-rows: auto;
  column-gap: $contentColumnSpace * 2;
}

@mixin twoColumnLayout_50p_50p {
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  justify-content: center;
}

@mixin twoColumnLayout_6bar_10bar {
  grid-template-columns: $w_6_ColumnWidth $w_10_ColumnWidth;
  grid-template-rows: auto;
  justify-content: center;
}

@mixin twoColumnLayout_4bar_6bar {
  grid-template-columns: $w_4_ColumnWidth $w_6_ColumnWidth;
  grid-template-rows: auto;
  // justify-content: center;
}

@mixin twoColumnLayout_10bar_6ar {
  grid-template-columns: $w_10_ColumnWidth $w_6_ColumnWidth;
  grid-template-rows: auto;
  justify-content: center;
}

@mixin fourColumnLayout_6bar_auto_auto_auto {
  grid-template-columns: $w_6_ColumnWidth auto auto auto;
  column-gap: $contentColumnSpace;
  justify-content: space-between;
}

// padding
@mixin paddingY {
  padding: min(20vh, 12.8rem) 0;
  @media screen and (max-width: $desktopBreakpoint) {
    padding: min(12vh, 8.4rem) 0;
  }
}

@mixin paddingTop {
  padding-top: min(6vh, 5.6rem);
}

@mixin paddingBottom {
  margin-bottom: min(12vh, 12rem);
}

@mixin marginBottom {
  margin-bottom: min(12vh, 12rem);
}

// decoration
@mixin border {
  content: '';
  position: absolute;
  background-color: black;
}

@mixin border-bottom {
  @include border;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
}

@mixin border-left {
  @include border;
  width: 1px;
  height: 100%;
  left: 0;
  top: 0;
}

@mixin border-right {
  @include border;
  width: 1px;
  height: 100%;
  right: 0;
  top: 0;
}

@mixin fade-start {
  transition: transform 350ms ease-out, opacity 400ms ease-out;
  opacity: 0;
  transform: translate3d(0, -1rem, 0);
}

@mixin fade-end {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

// debug
$debugColorRootContainer: rgba(0, 0, 255, 0.1);
$debugColorContentContainer: rgba(0, 0, 0, 0.2);
