@import '../../../styles/variables';
.root {
  display: grid;
  @include twoColumnLayout_4bar_6bar;
  background-color: $lightGray;
  padding: $padding;
  width: 100%;
  > span {
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 140%;
  }

  a {
    color: black;

    &:hover {
      color: #6bd9cf;
    }
  }
}

.linksWrapper {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $desktopBreakpoint) {
    flex-direction: column;
    .link {
      padding-bottom: $padding;
    }
  }
}

.link {
  display: flex;
  // justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: 300;
  font-size: 2.2rem;
  line-height: 140%;
  .img {
    margin-right: $padding;
    height: 2.2rem;
    width: 2.2rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}
