@import '../../../styles/variables';

.root {
  display: grid;
  flex-direction: column;
  width: $w_10_ColumnWidth;
  // TODO: fix padding om siste element, mulig anotha wrappa
  @include twoColumnLayout_50p_50p;
  // @include paddingTop;
  margin: 0 $contentColumnWidthPlusSpace 6rem $contentColumnWidthPlusSpace;

  a {
    text-decoration: none;
  }

  @media screen and (max-width: $desktopBreakpoint) {
    display: flex;
  }

  p {
    text-align: left;
    font-size: 2.2rem;
    line-height: 3.1rem;
    font-weight: 300;

    a {
      @media screen and (max-width: $desktopBreakpoint) {
        font-size: 18px;
      }

      >code {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 65vw;
        display: inline-block;
      }
    }
  }

  h4,
  span,
  >div,
  >div>div {
    @include fade-start;
  }

  &.in-view {

    h4,
    span,
    >div,
    >div>div {
      @include fade-end;
    }
  }
}

.content {
  position: relative;
  background-color: $beige;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $pageGutter * 3;

  >h4 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 300;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    padding-bottom: $padding;
  }

  @media screen and (max-width: $desktopBreakpoint) {
    justify-content: flex-start;
  }
}

.singleImg {
  width: 100%;
  height: 50vh;
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media screen and (max-width: $desktopBreakpoint) {
    min-height: 40vh;
    height: auto;
  }
}