@import '../../../styles/variables';
.root {
  display: grid;
  height: 60vh;
  overflow: hidden;
  color: white;
  width: 100%;
  align-items: center;
  justify-content: center;

  @include twoColumnLayout_100_and_10bar_width;
  // ANIM
  ._content {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    transition: opacity 350ms ease-out, transform 400ms ease-out;
  }
  &.in-view {
    ._content {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  // ANIM END
  ._content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    p {
      font-size: 32px;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: $desktopBreakpoint) {
    height: auto;
    min-height: 200px;
    overflow: visible;
    color: white;
    padding: 20px 0;
    @include oneColumnLayout;
    ._content {
      justify-content: flex-start;
      p {
        font-size: 25px;
        line-height: 34px;
        margin-bottom: 0;
        @include paddingY;
      }
    }
  }
}
