@import '../../../styles/variables';
.root {
    @include paddingY;
    h4 {
        margin-bottom: 3vh;
        text-transform: none;
        font-style: normal;
        font-weight: normal;
        font-size: 3.2rem;
        line-height: 4.4rem;
    }
    p {
        font-style: normal;
        font-weight: 300;
        font-size: 1.6rem;
        line-height: 155%;
        padding-bottom: 1.28rem;
    }
    a {
        text-decoration: none;
    }
}

.grid {
    display: grid;
    // @include fullWidth_60_40;
    @media screen and (max-width: $desktopBreakpoint) {
        display: unset;
    }
}

.sideContainer {
    justify-self: end;
    @media screen and (max-width: $desktopBreakpoint) {
        @include paddingTop;
    }
}

.link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 $padding;
    margin-bottom: 2px;
    background-color: lightGray;
    cursor: pointer;
    transition: all 250ms ease-out;
    span {
        font-weight: 300;
        font-size: 1.6rem;
        line-height: 1.9rem;
    }
    svg {
        transition: transform 220ms ease-out;
    }
    &:hover {
        svg {
            transform: rotate(0deg) translate3d(5px, 0, 0);
        }
    }
}

.link:hover {
    background-color: rgba(gray, 0.15);
}

.contactBox {
    margin-top: 66px;
    padding: 37px;
    background-color: lightGray;
    display: grid;
    grid-template-columns: 1fr 2fr;
    .infoWrapper {
        grid-column: 2 / 2;
        p {
            text-transform: uppercase;
        }
    }
}