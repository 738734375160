@import '../../../styles/variables';

.root {
  display: flex;
  flex-direction: column;
  // @include paddingY;
  @include marginBottom;
  width: $w_6_ColumnWidth;
  // height: 2842px;
  height: auto;
  padding-left: $contentColumnWidthPlusSpace;
  color: black;

  a {
    text-decoration: none;
  }

  >span,
  >span h4,
  >div h4 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding-bottom: 2.6rem;
    text-transform: uppercase;
  }

  >span,
  >div {
    font-family: 'Roboto Mono';
    font-weight: 400;
    letter-spacing: 0.02rem;
    // text-transform: uppercase;
  }

  >span,
  >h4,
  >div {
    // @include fade-start;
  }

  @media screen and (max-width: $desktopBreakpoint) {
    max-width: 95%;
  }

  &.in-view {

    >span,
    >h4,
    >div {
      @include fade-end;
    }
  }

  >div {
    >div {
      >div {
        >p {
          // margin-left: 18.4%;
        }
      }
    }
  }
}

.left {
  @media screen and (max-width: $desktopBreakpoint) {
    display: flex;
    width: $w_14_ColumnWidth;
    padding-left: $contentColumnWidthPlusSpace;
    box-sizing: border-box;
    width: 90%;

    >span,
    >h4 {
      line-height: 2.6rem;
    }
  }
}

.center {
  display: flex;
  width: $w_14_ColumnWidth;
  padding-left: $contentColumnWidthPlusSpace;
  box-sizing: border-box;

  @media screen and (max-width: $desktopBreakpoint) {
    width: 90%;

    >span,
    >h4 {
      line-height: 2.6rem;
    }
  }
}

.right {
  padding-left: $w_6_ColumnWidth + $contentColumnSpace;

  @media screen and (max-width: $desktopBreakpoint) {
    padding-left: unset;
  }
}

.left,
.center,
.right {

  p,
  h4 {
    text-align: left;
    font-size: 2.2rem;
    line-height: 3.1rem;
    font-weight: 300;
  }

  h4 {
    font-size: 1.5rem;
  }
}