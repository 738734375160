@import '../../../styles/variables';
.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include paddingY;
    width: 100%;
}

.singleImg {
    max-width: 760px;
    display: flex;
    width: 100%;
    height: 50vh;
    margin-left: $contentColumnWidthPlusSpace;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.caption {
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0.3);
    padding-left: $contentColumnWidthPlusSpace;
    padding-right: $contentColumnWidthPlusSpace;
}