@import '../../../styles/variables';
.root {
  display: flex;
  justify-content: center;
  @include paddingY;
  @include full-width;
  background-color: black;
  color: white;
  @media screen and (max-width: $desktopBreakpoint) {
    padding-left: $padding;
    padding-right: $padding;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media screen and (max-width: $desktopBreakpoint) {
      max-width: 95%;
    }
  }
  p {
    font-weight: normal;
    font-size: 2.5rem;
    padding-bottom: $padding * 2.5;
  }
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: normal;
  svg,
  path {
    transition: all 250ms ease-out;
  }
  svg:hover {
    fill: white;
    path {
      fill: black;
    }
  }
  svg {
    padding-left: $padding;
  }
}
