@import '../../../../styles/variables';
.root {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include paddingY;
  background-color: $lightGray;
  color: black;
  width: 100%;
  @media screen and (max-width: $desktopBreakpoint) {
    padding-top: $componentYPadding;
    padding-bottom: $componentYPadding;
  }
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate3d(-50%, 0, 0);
    background-color: inherit;
    width: 100vw;
    height: 100%;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 140%;
    color: #000000;
    margin-bottom: 4rem;
  }
  > div,
  > button {
    z-index: 1;
  }
  .input-row {
    display: flex;
    flex-direction: row;
    > span {
      margin: 0 1.28rem;
      flex: 1;
    }
    > span:first-of-type {
      margin: 0 1.28rem 0 0;
    }
    > span:last-of-type {
      margin: 0 0 0 1.28rem;
    }
  }

  .single-input-row {
    display: flex;
    flex-direction: row;
    > span {
      margin: 0 0;
      flex: 1;
    }
  }
  form {
    display: flex;
    flex-direction: column;
  }
}

.container {
  width: $w_10_ColumnWidth;

  p {
    font-size: 1.845rem;
    font-weight: 300;
  }
}

// .checkbox {
//   text-align: left;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   input[type='checkbox'] {
//     height: 26px;
//     width: 26px;
//     margin: 0 $fontSize 0 0;
//     padding: 0;
//     background: white;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     border: none;
//     border-radius: 2px;
//   }
//   input[type='checkbox']:checked {
//     background: black;
//   }
// }
.submitBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 15px;
  min-width: 10px;
  margin: 0 5px;
  margin-top: 8rem;
  width: 94px;
  border-radius: 15px;
  background-color: transparent;
  border: 1px solid black;
  transition: background-color 250ms ease, color 300ms ease-out;
  svg {
    path {
      transition: fill 250ms ease, color 300ms ease-out;
    }
  }
  &:hover {
    color: white;
    background-color: black;
    svg {
      path {
        fill: white;
      }
    }
  }
}

.noSubmitBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  min-width: 10px;

  margin-top: 8rem;
  width: 94px;

  background-color: transparent;
}
