@import '../../../styles/variables';
.root {
  position: relative;
  display: flex;
  width: calculateGridWidth(9, 0);
  flex-direction: column;
  // @include paddingY;
  @include marginBottom;
  margin-left: $contentColumnWidthPlusSpace * 1.5;
  margin-right: $contentColumnWidthPlusSpace * 1.5;

  h2 {
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 3.08rem;
    margin-bottom: 0.5rem;
  }

  .menu {
    display: flex;
    flex-wrap: wrap;

    > button {
      position: relative;
      display: inline-block;
      font-family: 'Times New Roman';
      font-style: italic;
      font-family: 'Roboto Mono';
      white-space: nowrap;
      font-size: 1.4rem;
      // line-height: 22px;
      cursor: pointer;
      font-weight: 300;
      height: 2.6rem;

      text-transform: none;

      &:after {
        content: attr(data-text);
        position: absolute;
        left: 0;
        font-weight: 300;
        color: transparent;
      }

      &:not(:last-of-type) {
        margin-right: 1.6rem;
      }

      &:hover {
        color: transparent;

        &:after {
          color: #39f3e2;
        }
      }

      &.active {
        color: transparent;
        &:after {
          color: #39f3e2;
        }
      }
    }
  }

  .grid-layout {
    position: relative;
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;

    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(400px, 20vw);

    @media screen and (max-width: $desktopBreakpoint) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: $largePhoneBreakpoint) {
      grid-template-columns: 1fr;
    }

    width: 100%;

    margin-top: 3rem;
  }
}
