@import '../../../../styles/variables';

.root {
  position: relative;
  background-color: #dce2e4;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .image-wrapper {
    height: 60%;
    width: 100%;

    > span {
      //       height: 100%;
      // position: relative;
      background-color: white;
      padding: 1rem;
      display: inline-block;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .info-wrapper {
    h4,
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: #000000;
    }

    h4 {
      margin-bottom: 1rem;
    }

    P {
      font-weight: 300;
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
    padding: 2rem 2rem 3rem 2rem;
  }
}
