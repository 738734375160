@import '../../../styles/variables';

.root {
  display: flex;
  width: 100%;
  height: 65vh;
  justify-content: center;
  align-items: center;

  @include oneColumnLayout;
  @include full-width;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media screen and (max-width: $desktopBreakpoint) {
    height: 22vh;
  }
}
