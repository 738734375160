@import '../../../styles/variables';

.root {
  @media screen and (max-width: $desktopBreakpoint) {
    @include full-width;
    width: unset;
    min-width: unset;
  }
}
.wrapper {
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: $w_6_ColumnWidth;
  min-width: 400px;
  padding: $padding * 2;

  @media screen and (max-width: $desktopBreakpoint) {
    width: unset;
    min-width: unset;
  }
}
.img {
  height: 200px;
  width: 300px;

  background-repeat: no-repeat;
  background-position: center center;

  background-size: contain;
}
