@import '../../../styles/variables';
.root {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: $contentColumnWidthPlusSpace;
  @include paddingY;
  h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 140%;
    margin-bottom: 8rem;
  }
}
